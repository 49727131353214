import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Stepper } from 'hds-react';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/stepper/customisation",
  "title": "Stepper - Customisation"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Playground = makeShortcode("Playground");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "customisation",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation`}<a parentName="h2" {...{
        "href": "#customisation",
        "aria-label": "customisation permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "customisation-properties",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation properties`}<a parentName="h3" {...{
        "href": "#customisation-properties",
        "aria-label": "customisation properties permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`You can use the `}<inlineCode parentName="p">{`theme`}</inlineCode>{` property to customise the component. See all available theme variables in the table below.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Theme variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--hds-not-selected-step-label-color`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Colour of a non-selected step label.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--hds-step-background-color`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Background colour of a step circle.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--hds-step-content-color`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Colour of elements inside a step.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--hds-stepper-background-color`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Background colour of the stepper container.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--hds-stepper-color`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Highlight colour of the stepper.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--hds-stepper-disabled-color`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Colour of a disabled step.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--hds-stepper-focus-border-color`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Colour of a step focus border.`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "customisation-example",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation example`}<a parentName="h3" {...{
        "href": "#customisation-example",
        "aria-label": "customisation example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`{() => {
  const commonReducer = (stepsTotal) => (state, action) => {
    switch (action.type) {
      case 'completeStep': {
        const activeStepIndex = action.payload === stepsTotal - 1 ? stepsTotal - 1 : action.payload + 1;
        return {
          activeStepIndex,
          steps: state.steps.map((step, index) => {
            if (index === action.payload && index !== stepsTotal - 1) {
              // current one but not last one
              return {
                state: StepState.completed,
                label: step.label,
              };
            }
            if (index === action.payload + 1) {
              // next one
              return {
                state: StepState.available,
                label: step.label,
              };
            }
            return step;
          }),
        };
      }
      case 'setActive': {
        return {
          activeStepIndex: action.payload,
          steps: state.steps.map((step, index) => {
            if (index === action.payload) {
              return {
                state: StepState.available,
                label: step.label,
              };
            }
            return step;
          }),
        };
      }
      default:
        throw new Error();
    }
  };
  const reducer = commonReducer(5);
  const initialState = {
    activeStepIndex: 0,
    steps: [
      {
        label: 'Step 1',
        state: StepState.available,
      },
      {
        label: 'Step 2',
        state: StepState.disabled,
      },
      {
        label: 'Step 3',
        state: StepState.disabled,
      },
      {
        label: 'Step 4 - longer text',
        state: StepState.disabled,
      },
      {
        label: 'Step 5',
        state: StepState.disabled,
      },
    ],
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const lastStep = state.activeStepIndex === state.steps.length - 1;
  return (
    <div>
      <Stepper
        steps={state.steps}
        language="en"
        selectedStep={state.activeStepIndex}
        onStepClick={(event, stepIndex) => dispatch({ type: 'setActive', payload: stepIndex })}
        theme={{
          '--hds-not-selected-step-label-color': 'var(--color-black-90)',
          '--hds-step-background-color': 'var(--color-black-5)',
          '--hds-step-content-color': 'var(--color-black-90)',
          '--hds-stepper-background-color': 'var(--color-white)',
          '--hds-stepper-color': 'var(--color-black-90)',
          '--hds-stepper-disabled-color': 'var(--color-black-30)',
          '--hds-stepper-focus-border-color': 'var(--color-black-90)'
        }}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-end',
          gap: '24px',
        }}
      >
        <Button
          disabled={state.activeStepIndex === 0}
          variant="secondary"
          onClick={() => dispatch({ type: 'setActive', payload: state.activeStepIndex - 1 })}
          style={{ height: 'fit-content', width: 'fit-content' }}
          iconLeft={<IconArrowLeft />}
          theme="black"
        >
          Previous
        </Button>
        <Button
          variant={lastStep ? 'primary' : 'secondary'}
          onClick={() => dispatch({ type: 'completeStep', payload: state.activeStepIndex })}
          style={{ height: 'fit-content', width: 'fit-content' }}
          iconRight={lastStep ? undefined : <IconArrowRight />}
          theme="black"
        >
          {lastStep ? 'Send' : 'Next'}
        </Button>
      </div>
    </div>
  );
}}
`}</code></pre>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      